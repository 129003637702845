import $ from '@vaersaagod/tools/Dom';
import Revealer from '../lib/Revealer';

export default el => {

    const $el = $(el);
    const $button = $el.find('button');

    const onClick = () => {
        const container = $el.parent().find('[data-showmore-item]').parent().get(0);
        if (!container) {
            return;
        }
        const hiddenItems = $(container).find('[data-showmore-item][hidden]').get().slice(0, 4);
        if (hiddenItems) {
            $(hiddenItems).each(item => {
                item.hidden = false;
            });
        }
        Revealer.update();
        if (!$(container).find('[data-showmore-item][hidden]').get().length) {
            $el.remove();
        }
    };

    const init = () => {
        $button.on('click', onClick);
    };

    const destroy = () => {
        $button.off('click');
    };

    return {
        init,
        destroy
    };

};
