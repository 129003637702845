/* global google */
import $ from '@vaersaagod/tools/Dom';
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);

    const mapsLoader = new GoogleMapsLoader({
        apiKey: props.apiKey,
        version: '3.43'
    });

    const createMap = () => {

        const { center, mapUrl, markerUrl } = props;
        const mapContainer = $el.find('[data-map]').get(0);
        const tl = gsap.timeline({ paused: true })
            .fromTo(mapContainer, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 1,
                ease: 'Cubic.easeOut'
            });

        mapsLoader.load().then(() => {

            const location = new google.maps.LatLng(parseFloat(center.lat), parseFloat(center.lng));

            const map = new google.maps.Map(mapContainer, {
                center: location,
                zoom: 15,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: true
            });

            const markerId = `marker-${new Date().getTime()}`;

            const marker = new google.maps.Marker({
                position: location,
                icon: markerUrl,
                id: markerId,
                map
            });

            marker.addListener('click', () => {
                window.open(mapUrl);
            });

            requestAnimationFrame(() => {
                tl.play();
            });

        });
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                createMap();
                observer.unobserve(entry.target);
            }
        });
    });

    const init = () => {
        observer.observe(el);
    };

    const destroy = () => {
        observer.disconnect();
    };

    return {
        init,
        destroy
    };

};
