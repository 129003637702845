import $ from '@vaersaagod/tools/Dom';
import deburr from 'lodash/deburr';
import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const defaultImageId = $el.find('[data-imageid]').data('imageid');
    const values = $el.find('[data-value]').get();

    let currentImageId = defaultImageId;
    let showImageTimer = null;
    let imageResetTimer = null;

    const showImage = imageId => {

        if (imageResetTimer) {
            clearTimeout(imageResetTimer);
            imageResetTimer = null;
        }

        if (showImageTimer) {
            clearTimeout(showImageTimer);
            showImageTimer = null;
        }

        if (imageId === currentImageId) {
            return;
        }

        currentImageId = imageId;

        const imagesToHide = $el.find('[data-imageid]:not([hidden])').get();

        if (imagesToHide.length) {
            gsap.timeline()
                .to(imagesToHide, {
                    opacity: 0,
                    duration: 0.2
                }, 0)
                .to(imagesToHide, {
                    scale: 0.9,
                    transformOrigin: 'right center'
                }, 0)
                .set(imagesToHide, { hidden: true });
        }

        const imageToDisplay = $el.find(`[data-imageid="${imageId}"]`).get(0);
        if (imageToDisplay) {
            gsap.killTweensOf(imageToDisplay);
            const tween = gsap.timeline({ paused: true })
                .to(imageToDisplay, { opacity: 1, duration: 0.3, ease: 'Cubic.easeIn' }, 0)
                .fromTo(imageToDisplay, { scale: 0.9 }, { scale: 1, duration: 0.5, transformOrigin: 'right center', ease: 'Back.easeOut' }, 0);
            const img = $(imageToDisplay).find('img').get(0);
            const isLoaded = $(img).hasClass('lazyloaded');
            imageToDisplay.hidden = false;
            if (isLoaded) {
                tween.play();
            } else {
                $(img).on('lazyloaded', () => {
                    tween.play();
                });
            }
        }
    };

    const resetImage = () => {
        if (imageResetTimer) {
            clearTimeout(imageResetTimer);
            imageResetTimer = null;
        }
        showImage(defaultImageId);
    };

    const filterValues = filter => {
        if (!filter) {
            if (values.length) {
                gsap.set(values, { hidden: false });
            }
            return;
        }
        const regex = new RegExp(deburr(filter), 'gi');
        const hiddenValues = values.filter(({ dataset }) => !deburr(dataset.value).match(regex));
        if (hiddenValues.length) {
            gsap.set(hiddenValues, { hidden: true });
        }
        const visibleValues = values.filter(({ dataset }) => deburr(dataset.value).match(regex));
        if (visibleValues.length) {
            gsap.set(visibleValues, { hidden: false });
        }
    };

    const onValueFocus = e => {
        const { triggerTarget: target } = e;
        if (showImageTimer) {
            clearTimeout(showImageTimer);
        }
        showImageTimer = setTimeout(() => {
            showImage($(target).data('image'));
        }, 100);
    };

    const onMouseLeave = () => {
        if (imageResetTimer) {
            clearTimeout(imageResetTimer);
        }
        if (showImageTimer) {
            clearTimeout(showImageTimer);
            showImageTimer = null;
        }
        imageResetTimer = setTimeout(resetImage, 500);
    };

    const onSearch = () => {
        const query = $el.find('[data-search]').val().toString().trim();
        filterValues(query);
    };

    const init = () => {
        $el
            .on('mouseover focusin', '[data-value]', onValueFocus)
            .on('mouseleave', onMouseLeave)
            .on('keyup', '[data-search]', onSearch);
    };

    const destroy = () => {
        $el.off('mouseover focusin mouseleave keyup');
    };

    return {
        init,
        destroy
    };

};
