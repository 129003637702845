import './lib/polyfills';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';

import Revealer from './lib/Revealer';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    Revealer.init();
};

require('doc-ready')(init);
