import $ from '@vaersaagod/tools/Dom';

import Modal from '../lib/Modal';

export default (el, props) => {

    const $el = $(el);

    const onClick = e => {
        e.preventDefault();
        Modal.open(props.embed);
    };

    const init = () => {
        $el.find('a').on('click', onClick);
    };

    const destroy = () => {
        $el.find('a').off('click', onClick);
    };

    return {
        init,
        destroy
    };

};
