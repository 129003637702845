import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import { loadFlickity } from '../lib/async-bundles';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    const $slider = $el.find('[data-slider]');

    const { options, startAt } = props || {};

    let Flickity;
    let flckty;

    let selectedIndex = 0;
    if (startAt) {
        const slideUids = $slider.find('[data-slide]').get().map(slide => $(slide).data('slide'));
        selectedIndex = slideUids.indexOf(startAt) || 0;
        console.log({ startAt, selectedIndex, slideUids });
    }

    let touchingFlickity = false;
    let touchStartCoords;

    const onSlideChange = () => {
        selectedIndex = flckty.selectedIndex;
    };

    const destroyFlickity = () => {
        if (!flckty) {
            return;
        }
        flckty.off('change');
        flckty.destroy();
        flckty = null;
        $slider.css({
            width: '',
            height: ''
        });
        $el.removeClass('js-has-flickity');
    };

    const initFlickity = () => {

        if (!Flickity) {
            return;
        }

        const firstBoot = !flckty;

        destroyFlickity();

        $el.addClass('js-has-flickity');

        const sliderHeight = $slider.height();

        $slider.css({
            width: `${$slider.width()}px`,
            height: `${sliderHeight}px`
        });

        flckty = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            groupCells: true,
            adaptiveHeight: false,
            resize: false,
            wrapAround: false,
            cellAlign: 'left',
            initialIndex: selectedIndex,
            accessibility: true,
            contain: true,
            dragThreshold: 15,
            on: {
                dragStart: () => {
                    $slider.find('a,button').css({
                        pointerEvents: 'none'
                    });
                },
                dragEnd: () => {
                    $slider.find('a,button').css({
                        pointerEvents: ''
                    });
                }
            },
            ...options
            // selectedAttraction: 0.01,
            // friction: 0.15
        });

        flckty.on('change', onSlideChange);

        if (!firstBoot) {
            return;
        }

        const nextBtn = $el.find('[data-nextbtn]').get(0);
        const prevBtn = $el.find('[data-prevbtn]').get(0);
        if (nextBtn && prevBtn) {
            gsap.timeline({
                delay: 0.5
            })
                .to([nextBtn, prevBtn], {
                    opacity: 1,
                    duration: 0.5,
                    ease: 'Sine.easeIn'
                }, 0)
                .fromTo(nextBtn, {
                    x: -50
                }, {
                    x: 0,
                    duration: 1,
                    ease: 'Cubic.easeOut'
                }, 0)
                .fromTo(prevBtn, {
                    x: 50
                }, {
                    x: 0,
                    duration: 1,
                    ease: 'Cubic.easeOut'
                }, 0)
                .add(() => {
                    $el.find('[data-nextbtn],[data-prevbtn]').css({
                        pointerEvents: 'auto'
                    });
                }, 0);
        }

    };

    const next = () => {
        if (!flckty) {
            return;
        }
        flckty.next();
    };

    const prev = () => {
        if (!flckty) {
            return;
        }
        flckty.previous();
    };

    const onResize = () => {
        requestAnimationFrame(initFlickity);
    };

    const onDocTouchStart = e => {
        if (e.target.closest('.flickity-slider')) {
            touchingFlickity = true;
        } else {
            touchingFlickity = false;
            return;
        }

        touchStartCoords = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY
        };
    };

    const onDocTouchMove = e => {
        if (!(touchingFlickity && e.cancelable)) {
            return;
        }

        const moveVector = {
            x: e.touches[0].pageX - touchStartCoords.x,
            y: e.touches[0].pageY - touchStartCoords.y
        };

        if (Math.abs(moveVector.x) > 7) {
            e.preventDefault();
        }
    };

    const init = () => {

        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
        });

        document.body.addEventListener('touchstart', onDocTouchStart);
        document.body.addEventListener('touchmove', onDocTouchMove, { passive: false });

        $el
            .on('click', '[data-nextbtn]', next)
            .on('click', '[data-prevbtn]', prev);
    };

    const destroy = () => {
        if (flckty) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
        $el.off('click');
        document.body.removeEventListener('touchstart', onDocTouchStart);
        document.body.removeEventListener('touchmove', onDocTouchMove);
    };

    return {
        init,
        destroy
    };

};
