import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import shuffle from 'lodash/shuffle';
import gsap from 'gsap';

export default el => {

    const { id } = el;

    const $el = $(el);
    const $wrap = $el.find('[data-wrap]');
    const $stickyLogo = $el.find('[data-stickylogo]');
    const $inner = $el.find('[data-inner]');
    const $burger = $el.find('[data-burger]');
    const $menu = $el.find('[data-menu]');

    let prevScrollTop = 99999999999;
    let stickyLogoOffset = null;
    let isMenuOpen = false;
    let isMinimized = false;
    let isHidden = false;
    let focusedElementBeforeOpen = null;

    const isSmall = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const onScroll = () => {
        const { scrollTop, height: viewportHeight } = Viewport;
        if (isMenuOpen) {
            prevScrollTop = scrollTop;
            return;
        }
        const direction = scrollTop > prevScrollTop ? 'down' : 'up';
        let minimizeOffset = stickyLogoOffset;
        if (direction === 'up') {
            minimizeOffset += 60;
        }
        // Maybe minimize
        if (!isMinimized && scrollTop >= minimizeOffset) {
            isMinimized = true;
            gsap.timeline()
                .to($inner.get(0), { y: -35, duration: 0.3, ease: 'Quad.easeOut' }, 0)
                .to($stickyLogo.get(0), { opacity: 0, duration: 0.15 }, 0.15);
            $el.addClass('js-is-mini');
        } else if (isMinimized && scrollTop < minimizeOffset) {
            isMinimized = false;
            gsap.timeline()
                .to($inner.get(0), { y: 0, duration: 0.3, ease: 'Quad.easeOut' }, 0)
                .to($stickyLogo.get(0), { opacity: 1, duration: 0.3, ease: 'Sine.easeIn' }, 0.05);
            $el.removeClass('js-is-mini');
        }
        // Maybe hide
        const diff = Math.abs(prevScrollTop - scrollTop);
        if (diff >= 5 || scrollTop < 10) {
            let hideOffset = viewportHeight - (stickyLogoOffset + 30);
            if (hideOffset <= minimizeOffset) {
                hideOffset += 100;
            }
            if (!isHidden && direction === 'down' && scrollTop >= hideOffset) {
                isHidden = true;
                gsap.timeline()
                    .to($wrap.get(0), { yPercent: -100, duration: 0.3, ease: 'Quad.easeIn' }, 0);
            } else if (isHidden && direction === 'up') {
                isHidden = false;
                gsap.timeline()
                    .to($wrap.get(0), { yPercent: 0, duration: 0.3, ease: 'Sine.easeOut' }, 0)
                    .set($wrap.get(0), { clearProps: 'y' });
            }
        }
        prevScrollTop = scrollTop;
    };

    const openMenu = (tween = true) => {
        if (isMenuOpen) {
            return;
        }
        isMenuOpen = true;
        $el.addClass('js-menu-open');
        $menu.get(0).hidden = false;
        focusedElementBeforeOpen = document.activeElement || $el.find('[data-burger]').get(0);
        requestAnimationFrame(() => {
            Viewport.lockTabbing($menu.get(0), $menu.find('[data-burger]').get(0));
        });
        window.location.hash = '';
        if (window.history && window.history.replaceState) {
            window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
        }
        const tl = gsap.timeline()
            .fromTo($menu.get(0), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo($menu.find('[data-items]').get(), {
                scale: 1.1
            }, {
                scale: 1,
                duration: 1,
                ease: 'Quint.easeOut'
            }, 0);
        if (!tween) {
            tl.seek(tl.duration(), false);
        }
    };

    const closeMenu = (tween = true) => {
        if (!isMenuOpen) {
            return;
        }
        isMenuOpen = false;
        $el.removeClass('js-menu-open');
        Viewport.releaseTabbing(focusedElementBeforeOpen);
        const tl = gsap.timeline({
            onComplete() {
                $menu.get(0).hidden = true;
            }
        })
            .to($menu.get(0), {
                opacity: 0,
                duration: 0.35
            }, 0);
        if (!tween) {
            tl.seek(tl.duration(), false);
        }
        onScroll();
    };

    const onBreakpoint = () => {
        if (!isSmall()) {
            closeMenu(false);
        }
        onScroll();
    };

    const onResize = () => {
        stickyLogoOffset = $inner.height();
        onScroll();
    };

    const onBurgerClick = e => {
        e.preventDefault();
        if (isMenuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onHashChange = () => {
        if (window.location.hash === `#${id}`) {
            openMenu();
        }
    };

    const init = () => {
        $burger.on('click', onBurgerClick);
        // Should menu be open?
        if (window.location.hash === `#${id}`) {
            openMenu(false);
        }
        window.addEventListener('hashchange', onHashChange);
        Viewport.on('scroll', onScroll);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        closeMenu(false);
        $burger.off('click', onBurgerClick);
        window.removeEventListener('hashchange', onHashChange);
        Viewport.off('scroll', onScroll);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
