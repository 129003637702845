import $ from '@vaersaagod/tools/Dom';

export default el => {

    const $el = $(el);
    const $input = $el.find('input');

    const onInputChange = () => {
        if ($input.val()) {
            $el.addClass('js-has-value');
        } else {
            $el.removeClass('js-has-value');
        }
    };

    const init = () => {
        $el.on('change keyup', 'input', onInputChange);
        onInputChange();
    };

    const destroy = () => {
        $el.on('change keyup', 'input', onInputChange);
    };

    return {
        init,
        destroy
    };

};
