import $ from '@vaersaagod/tools/Dom';
import Modal from '../lib/Modal';

export default el => {
    $(el).on('click', e => {
        e.preventDefault();
        const href = $(el).attr('href');
        Modal.openUrl(href);
    });
};
