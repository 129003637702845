import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';

export default el => {

    const $el = $(el);

    const toggle = id => {
        const panel = $el.find(`#${id}`).get(0);
        if (!panel) {
            return;
        }
        const btn = $el.find(`[data-accordion-toggle][aria-controls="${id}"]`).get(0);
        const isExpanded = $(btn).attr('aria-expanded') === 'true';
        if (!isExpanded) {
            panel.hidden = false;
            $(panel).find('[x-data-component]').each(node => {
                $(node).attr('data-component', $(node).attr('x-data-component'));
                node.removeAttribute('x-data-component');
            });
            $(panel).find('[x-data-component-props]').each(node => {
                $(node).attr('data-component-props', $(node).attr('x-data-component-props'));
                node.removeAttribute('x-data-component-props');
            });
            Components.init(panel);
            $(btn).attr('aria-expanded', 'true');
        } else {
            panel.hidden = true;
            $(btn).attr('aria-expanded', 'false');
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const id = $(target).attr('aria-controls');
        toggle(id);
    };

    const init = () => {
        const isLivePreview = $('html').hasClass('is-live-preview');
        $el.find('a[data-accordion-toggle]').each(a => {
            const href = $(a).attr('href');
            const target = $el.find(href).get(0);
            $(a).attr({
                role: 'button',
                'aria-expanded': isLivePreview ? 'true' : 'false',
                'aria-controls': target.id
            });
            const shouldBeExpanded = !isLivePreview && href === window.location.hash;
            if (shouldBeExpanded) {
                toggle(target.id);
            }
        });
        $el.on('click', '[data-accordion-toggle]', onToggleClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
