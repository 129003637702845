import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => ({
    init: () => {
        $(el).on('click', e => {
            const id = $(e.currentTarget).attr('href');
            const target = $(id).get(0);
            if (!target) {
                return true;
            }
            Viewport.scrollTo(target);
            e.preventDefault();
            return false;
        });
    },
    destroy: () => {
        $(el).off('click');
    }
});
