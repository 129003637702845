import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Viewport from '@vaersaagod/tools/Viewport';
import agent from '@vaersaagod/tools/request';
import gsap from 'gsap';

const $modal = $('#modal');
const $content = $modal.find('[data-modal-content]');

let isOpen = false;
let focusedElementBeforeOpen = null;
let ajaxRequest;
let closeTimeline;

const setHtml = html => {

    $modal.get(0).hidden = false;

    const tl = gsap.timeline();

    if ($content.html()) {
        tl
            .to($content.get(0), {
                opacity: 0,
                duration: 0.15
            })
            .add(() => {
                Components.destroy($content);
                $content.html(html);
                Components.init($content);
                $($content.get(0).children).css({
                    pointerEvents: 'auto'
                });
            });
    } else {
        gsap.set($content.get(0), { opacity: 0 });
        $content.html(html);
        Components.init($content);
        $($content.get(0).children).css({
            pointerEvents: 'auto'
        });
    }

    if (html) {
        tl
            .to($content.get(0), {
                opacity: 1,
                duration: 0.35
            }, 'in+=0.25')
            .fromTo($content.get(0), {
                scale: 0.85
            }, {
                scale: 1,
                duration: 1,
                ease: 'Quint.easeOut'
            }, 'in+=0.25');
    }

};

const close = () => {

    if (!isOpen) {
        return;
    }

    isOpen = false;
    Viewport.releaseTabbing(focusedElementBeforeOpen);
    focusedElementBeforeOpen = null;

    gsap.set([$modal.get(0), $content.get(0)].concat($content.get(0).children).filter(node => !!node), { pointerEvents: 'none' });

    closeTimeline = gsap.timeline({
        onComplete: () => {
            $modal.get(0).hidden = true;
            gsap.set([$modal.get(0), $content.get(0)], { clearProps: 'all' });
            Components.destroy($content);
            $content.html('');
            closeTimeline = null;
        }
    })
        .to($modal.get(0), {
            opacity: 0,
            duration: 0.35
        }, 0)
        .to($content.get(0), {
            opacity: 0,
            duration: 0.35
        }, 0)
        .to($content.get(0), {
            scale: 0.98,
            duration: 0.75,
            ease: 'Cubic.easeIn'
        }, 0);

    if (ajaxRequest) {
        ajaxRequest.abort();
        ajaxRequest = null;
    }

};

const open = html => {

    if (isOpen) {
        return;
    }

    isOpen = true;

    if (closeTimeline) {
        closeTimeline.kill();
        closeTimeline = null;
    }

    focusedElementBeforeOpen = document.activeElement || null;

    setHtml(html);

    Viewport.lockTabbing($modal.get(0), $modal.find('[data-modal-closebtn]').get(0));

    gsap.timeline()
        .to($modal.get(0), {
            opacity: 1,
            duration: 0.35
        });
};

const openUrl = url => {

    if (ajaxRequest) {
        ajaxRequest.abort();
    }

    const spinner = $modal.find('[data-modal-spinner]').get(0);
    spinner.hidden = false;
    gsap.set(spinner, { opacity: 1 });

    open();

    ajaxRequest = agent.get(url);
    ajaxRequest
        .then(({ text, status }) => {
            if (status !== 200) {
                close();
                return;
            }
            setHtml(text);
        })
        .catch(error => {
            console.error(error);
        })
        .then(() => {
            ajaxRequest = null;
            gsap.to(spinner, {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                    spinner.hidden = true;
                }
            });
        });
};

$('body').on('keyup', e => {
    if (!isOpen) {
        return;
    }
    const key = e.which || e.keyCode || null;
    if (key === 27) {
        close();
    }
});

$modal.on('click', '[data-modal-closebtn],[data-modal-overlay]', close);

export default {
    open,
    openUrl,
    close
};
