import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import Revealer from '../lib/Revealer';

export default el => {

    const $el = $(el);
    const $button = $el.find('button');

    let isLoading = false;

    const setHtml = html => {
        const container = $('[data-loadmore-item]').parent().get(0);
        if (!container) {
            console.warn('No container for loadmore items');
            return;
        }
        const $newPage = $('<div/>').html(html);
        const $newItems = $newPage.find('[data-loadmore-item]').get();
        if ($newItems.length) {
            $(container).append($newItems);
            Revealer.update();
        }
        const newButton = $newPage.find('[data-component="LoadMore"] button').get(0);
        if (newButton) {
            $button.attr('data-next', newButton.dataset.next);
        } else {
            $el.remove();
        }
    };

    const onClick = () => {
        if (isLoading) {
            return;
        }
        const { next: nextUrl } = $button.get(0).dataset;
        if (!nextUrl) {
            return;
        }
        isLoading = true;
        request
            .get(nextUrl)
            .then(({ text, status }) => {
                if (status !== 200) {
                    throw new Error();
                }
                setHtml(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });

    };

    const init = () => {
        $button.on('click', onClick);
    };

    const destroy = () => {
        $button.off('click');
    };

    return {
        init,
        destroy
    };

};
