import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';
import gsap from 'gsap';

export default form => {

    const $form = $(form);
    const $submit = $form.find('button[type="submit"]');
    const $requiredInputs = $form.find('input[required],input[type="password"]');
    const $error = $form.find('#error');

    let isSubmitting = false;

    const showReceipt = () => {
        const receipt = $form.find('[data-receipt]').get(0);
        if (!receipt) {
            return;
        }
        receipt.hidden = false;
        $form.find('input,textarea,select').each(input => {
            if (input.type === 'hidden') {
                return;
            }
            if (input.type === 'checkbox' || input.type === 'radio') {
                input.checked = false;
                return;
            }
            input.value = '';
            $(input).parent('.js-has-value').removeClass('js-has-value');
        });
        gsap.timeline()
            .fromTo(receipt, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 0);
    };

    const clearErrors = () => {
        $form.find('[data-errorfor]').each(error => {
            $(error).html('');
            error.hidden = true;
        });
    };

    const setErrors = errors => {
        clearErrors();
        if (!errors) {
            return;
        }
        const errorKeys = Object.keys(errors);
        console.log({ errorKeys });
        errorKeys.forEach(key => {
            const $inputErrors = $form.find(`[data-errorfor="${key}"]`);
            if (!$inputErrors.length) {
                return;
            }
            $inputErrors.html([].concat(errors[key]).join('<br/>'));
            $inputErrors.get(0).hidden = false;
        });
        const firstError = $form.find('[data-errorfor]:not([hidden])').get(0);
        if (!firstError) {
            return;
        }
        const firstErrorField = $form.find(`[aria-describedby*="${firstError.id}"]`).get(0);
        if (firstErrorField) {
            try {
                firstErrorField.focus();
                firstErrorField.select();
            } catch (error) {}
        }
    };

    const clearErrorMessage = () => {
        if (!$error.length) {
            return;
        }
        $error.get(0).hidden = true;
        $error.text('');
    };

    const setErrorMessage = message => {
        if (!$error.length) {
            return;
        }
        clearErrorMessage();
        if (!message) {
            return;
        }
        $error.text(message);
        $error.get(0).hidden = false;
    };

    const onRequiredInputChange = () => {
        let isValid = true;
        $requiredInputs.each(input => {
            if (!isValid) {
                return;
            }
            if (input.type === 'radio' || input.type === 'checkbox') {
                isValid = !!$form.find(`input[name="${input.name}"]:checked`).get(0);
            } else {
                isValid = !!input.value;
            }
        });
        if (!isValid) {
            $submit.addClass('disabled');
        } else {
            $submit.removeClass('disabled');
        }
    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;

        const data = serialize($form.get(0));

        const $submitLabel = $submit.find('[data-label]');
        const $submitSpinner = $submit.find('[data-spinner]');
        if ($submitLabel.length && $submitSpinner.length) {
            $submitSpinner.removeClass('hidden');
            gsap.timeline()
                .to($submitLabel.get(0), { opacity: 0, duration: 0.15 }, 0)
                .to($submitSpinner.get(0), { opacity: 1, duration: 0.15 }, 0);
        }

        request
            .post(window.location.href)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                if (status !== 200) {
                    setErrorMessage('Noe gikk dessverre galt. Prøv igjen senere!');
                    return;
                }
                if (!body.success) {
                    setErrors(body.errors || null);
                    setErrorMessage(body.message || null);
                    return;
                }
                clearErrors();
                clearErrorMessage();
                if (body.redirect) {
                    $submit.addClass('disabled');
                    window.location.href = body.redirect;
                } else {
                    showReceipt();
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isSubmitting = false;
                if ($submitLabel.length && $submitSpinner.length) {
                    gsap.timeline({
                        onComplete: () => {
                            $submitSpinner.addClass('hidden');
                        }
                    })
                        .to($submitLabel.get(0), { opacity: 1, duration: 0.15 }, 0)
                        .to($submitSpinner.get(0), { opacity: 0, duration: 0.15 }, 0);
                }
            });
    };

    const init = () => {
        $requiredInputs.on('keyup change', onRequiredInputChange);
        $form.on('submit', onSubmit);
        onRequiredInputChange();
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
        $requiredInputs.off('keyup change', onRequiredInputChange);
    };

    return {
        init,
        destroy
    };
};
