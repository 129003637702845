import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => {

    const $el = $(el);

    const onResize = () => {
        requestAnimationFrame(() => {
            $el.width('');
            const maxHeight = $el.parent().height();
            let height = $el.height();
            let width = $el.width();
            const ratio = width / height;
            if (height > maxHeight) {
                height = maxHeight;
                width = height * ratio;
                $el.width(width);
            }
        });
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
