import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const $sizer = $el.find('[data-sizer]');
    const $wrap = $el.find('[data-wrap]');
    const $inner = $el.find('[data-inner]');
    const $text = $el.find('[data-text]');
    const $scrollBtn = $el.find('[data-scrollbtn]');

    const article = $el.parent('article').get(0);
    const articleContent = article ? $(article).find('#content').get(0) : null;
    const articleBackgroundColor = $(article).css('background-color');
    const articleColor = $(article).css('color');

    let isFixed = false;
    let height;
    let timeline;

    const isLivePreview = $('html').hasClass('is-live-preview');

    const getBackgroundColorRgba = () => {
        const color = $el.css('background-color');
        const matches = /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*\d+[\.\d+]*)*\)/g.exec(color);
        return `rgba(${matches[1]}, ${matches[2]}, ${matches[3]}, 0)`;
    };

    const destroyTimeline = () => {
        if (!timeline) {
            return;
        }
        timeline.pause(0);
        timeline.kill();
        timeline = null;
        if (articleContent) {
            gsap.set([article, articleContent], { clearProps: 'all' });
        }
    };

    const createTimeline = () => {
        destroyTimeline();
        timeline = gsap.timeline({ paused: true });
        if (isFixed) {
            timeline
                .to($wrap.get(0), {
                    y: -(height * 0.1),
                    duration: Math.round(height * 0.75),
                    ease: 'none'
                }, 0);
        }
        timeline
            .to($scrollBtn.get(0), {
                opacity: 0,
                duration: Math.round(height * 0.35),
                ease: 'Sine.easeIn'
            }, 0)
            .to($text.get(0), {
                opacity: 0,
                duration: Math.round(height * 0.55),
                ease: 'Sine.easeIn'
            }, Math.round(height * 0.1));
        const maxScroll = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - Viewport.height;
        if (height <= maxScroll && articleContent && !isLivePreview) {
            timeline
                .fromTo([article, articleContent], {
                    backgroundColor: getBackgroundColorRgba(),
                    color: 'rgba(255, 255, 255, 1)'
                }, {
                    backgroundColor: articleBackgroundColor,
                    color: articleColor,
                    duration: Math.round(height * 0.5),
                    ease: 'Cubic.easeOut'
                }, Math.round(height * 0.5));
        }
    };

    const fix = () => {
        isFixed = true;
        $wrap.css({
            position: 'fixed'
        });
        $el.height(height);
    };

    const unfix = () => {
        isFixed = false;
        if (timeline) {
            timeline.pause(0);
            timeline.kill();
            timeline = null;
        }
        $el.height('');
        $wrap.css({
            position: ''
        });
    };

    const intro = () => {
        gsap.timeline()
            .fromTo($inner.get(0), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 1,
                ease: 'Sine.easeIn'
            });
    };

    const onScroll = () => {
        if (!timeline) {
            return;
        }
        const { scrollTop } = Viewport;
        const duration = timeline.duration();
        const progress = scrollTop / duration;
        if (progress <= 0) {
            timeline.progress(0, false);
        } else if (progress >= 1) {
            timeline.progress(1, false);
        } else {
            timeline.progress(progress, false);
        }
    };

    const onResize = () => {
        requestAnimationFrame(() => {
            const viewportHeight = $sizer.height();
            height = $wrap.height();
            if (height <= viewportHeight + 200) {
                fix();
            } else {
                unfix();
            }
            createTimeline();
            onScroll();
        });
    };

    const init = () => {
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        onResize();
        if (!isLivePreview) {
            intro();
        }
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        destroyTimeline();
    };

    return {
        init,
        destroy
    };

};
